import React, {Component} from "react";
import {graphql} from "gatsby";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import Meta from "../components/Meta";
import SubscribeForm from "../components/SubscribeForm";
import '../components/layout.scss';
import { store } from '../app/store';
import { Provider } from 'react-redux';

class SubscribePage extends Component {
    render() {

        let meta = {
            metaTitle: "Subscribe to our news & offers – [name]",
            metaDescription: "The form to allow you to subscribe for offers news & information on things to do in [displayLocaton]",
            ogTitle: "Subscribe to news & offers from the [name]",
            ogDescription: "Subscribe for offers news & information on activities in [area] ",
            twitterTitle: "Subscribe to the [housenamePosessive] news & offers",
            twitterDescription: "Subscribe for offers, news & information on things to do in [gl1], [gl2] and [gl3]",
            ogImage: null,
            twitterImage: null,
        }

      return (
          <>
            <Provider store={store}>
              <CssBaseline />
              <style>{"body{max-width:100%;overflow-x:hidden;}img{max-width:100%;}.gatsby-image-wrapper{position:relative;}.gatsby-image-wrapper picture{position:absolute;top:0;right:0;bottom:0;left:0;}.mb-0{margin-bottom: 0 !important;"}</style>
              <Meta meta={meta} title={meta.title} ii={this.props.data.wp.themeOptions.ThemeOptions.individualInnsBranding} />
              <SubscribeForm themeOptions={this.props.data.wp.themeOptions} />
            </Provider>
          </>
      )
    }
}

export const query = graphql`
query {
  wp {
    themeOptions {
      ThemeOptions {
        siteLogo {
          mediaItemUrl
        }
        individualInnsBranding
        subscribeFormImage {
          mediaItemUrl
          mediaItemUrlSharp {
            id
            publicURL
            childImageSharp {
              fluid(quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          } 
        }
        navBackgroundImage {
          mediaItemUrl
          mediaItemUrlSharp {
            id
            publicURL
            childImageSharp {
              fluid(quality: 80) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          } 
        }
      }
    }    
  }
}
`;

export default SubscribePage
