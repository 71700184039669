import React, { useState } from "react"
import { makeStyles } from "@material-ui/styles"
import Button from "@material-ui/core/Button/Button"
import arrowPrev from "../../images/arrow-prev-light.png"
import Link from "../Link"
import TextField from "@material-ui/core/TextField/TextField"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import axios from "axios"
// import ReactRecaptcha from "react-recaptcha";
import WarningIcon from "@material-ui/icons/Warning"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { trackSubscribe } from "../../services/gtm"

const useStyles = makeStyles(theme => ({
  subscribePage: {
    backgroundColor: theme.palette.secondary.main,
    padding: 22,
    position: "relative",
    minHeight: "100vh",
    [theme.breakpoints.up("md")]: {
      padding: 60,
    },
    "& div:focus": {
      outline: "none",
    },
    "& .site-logo": {
      display: "inline-block",
      marginBottom: 24,
      [theme.breakpoints.up("md")]: {
        marginBottom: 60,
      },
      "& img": {
        maxHeight: 30,
        [theme.breakpoints.up("lg")]: {
          maxHeight: 38,
        },
      },
    },
    "& .back-link": {
      color: theme.palette.secondary.contrastText,
      fontSize: 16,
      letterSpacing: "0.016em",
      lineHeight: 1.5,
      float: "right",
      "& .MuiButton-label": {
        "& span": {
          marginRight: 10,
          width: 18,
          height: 18,
          backgroundImage: "url(" + arrowPrev + ")",
          backgroundSize: "contain",
          backgroundPosition: "center",
          display: "inline-block",
          verticalAlign: "text-top",
        },
      },
    },
    "& .subscribe-page": {
      maxWidth: 1160,
      margin: "0 auto",
      "& .gatsby-image-wrapper": {
        display: "none",
      },
      [theme.breakpoints.up("md")]: {
        display: "flex",
        "& .form-wrapper": {
          flex: "0 0 270px",
          marginRight: 62,
        },
        "& .gatsby-image-wrapper": {
          display: "block",
          flex: "0 0 calc(100% - 332px)",
        },
      },
      "& h2": {
        color: theme.palette.secondary.contrastText,
        fontSize: 32,
        lineHeight: "1.1",
        marginBottom: 36,
        marginTop: 0,
        letterSpacing: "-0.008rem",
        fontWeight: "normal",
      },
    },
    "& .gatsby-image-wrapper": {
      display: "none",
    },
  },
  subscribePageTheme: {
    ...theme.subscribePageTheme,
  },
  label: {
    position: "relative",
    display: "block",
    fontSize: 14,
    textTransform: "uppercase",
    fontWeight: "normal",
    letterSpacing: "0.06em",
    fontFamily: theme.headerFont,
    marginBottom: 3,
    color: theme.palette.secondary.contrastText,
  },
  inputs: {
    width: "100%",
    border: "none",
    borderRadius: 25,
    overflow: "hidden",
    backgroundColor: "white",
    marginBottom: 12,
    "& div": {
      padding: "0 !important",
    },
    "& input, & textarea": {
      position: "relative",
      border: "none !important",
      backgroundColor: "white",
      fontSize: 12,
      fontFamily: theme.headerFont,
      letterSpacing: "0.01em",
      padding: "15px 20px",
      textTransform: "uppercase",
      height: "auto !important",
      lineHeight: "1.2",
    },
    "& textarea": {
      height: "100px !important",
      [theme.breakpoints.up("md")]: {
        height: "170px !important",
      },
    },
    "& fieldset": {
      display: "none",
    },
    "& .MuiInputBase-root": {
      marginBottom: "0 !important",
    },
    "&.has-error": {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  button: {
    fontFamily: theme.headerFont,
    padding: "12px 22px",
    boxShadow: "0 1px 3px rgba(0,0,0,0.2)",
    fontSize: 16,
    lineHeight: 1.2,
    fontWeight: 400,
    textAlign: "center",
    minWidth: 185,
    textTransform: "uppercase",
    margin: "24px auto 0",
    display: "table",
  },
  errors: {
    display: "block",
    position: "relative",
    border: "2px solid " + theme.palette.primary.dark,
    color: theme.palette.primary.dark,
    textAlign: "left",
    fontSize: 16,
    padding: 12,
    margin: "0 auto 24px",
    fontFamily: theme.headerFont,
    [theme.breakpoints.up("md")]: {
      maxWidth: 720,
    },
    "& svg": {
      verticalAlign: "bottom",
    },
  },
  // recaptcha: {
  //     position: 'relative',
  //     marginLeft: 'auto',
  //     marginRight: 'auto'
  // },
  validationError: {
    position: "relative",
    padding: "6px 20px",
    marginTop: "-12px",
    marginBottom: 15,
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    fontSize: 12,
    borderBottomLeftRadius: 22,
    borderBottomRightRadius: 22,
  },
  thanks: {
    display: "block",
    position: "relative",
    border: "2px solid " + theme.palette.secondary.contrastText,
    color: theme.palette.secondary.contrastText,
    textAlign: "left",
    fontSize: 16,
    padding: 12,
    margin: "0 auto 24px",
    fontFamily: theme.headerFont,
    [theme.breakpoints.up("md")]: {
      maxWidth: 700,
    },
    "& svg": {
      verticalAlign: "bottom",
    },
  },
}))

export default function SubscribeForm(props) {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query CrmId {
      wp {
        locationData {
          LocationData {
            crmId
          }
        }
      }
    }
  `)
  var crmID = data.wp.locationData.LocationData.crmId

  const [formError, setFormError] = useState([])
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formSending, setFormSending] = useState(false)

  const [forename, setForename] = useState("")
  const [surname, setSurname] = useState("")
  const [email, setEmail] = useState("")
  const [dateOfBirthString, setDateOfBirthString] = useState("")
  const [dateOfBirth, setDateOfBirth] = useState("")
  //   const [message, setMessage] = useState("")
  // const [recaptchaResponse, setRecaptchaResponse] = useState('');
  //   const emptyValidationErrors = {
  //     forename: [],
  //     surname: [],
  //     email: [],
  //     dob: [],
  //     // recaptcha: []
  //   }
  const [validationErrors, setValidationErrors] = useState({
    dob: ["You’ll need to be over 18 to subscribe"],
  })

  const submitForm = event => {
    // send
    setFormSending(true)
    let postData = {
      forename: forename,
      surname: surname,
      email: email,
      dob: dateOfBirth,
      house_id: crmID,
      // "g-recaptcha-response": recaptchaResponse,
      chosen_brand: crmID === null ? "Individual Inns" : null,
      source_details: "Website Subscribe Form",

      consent_email_marketing: true,
    }

    axios({
      method: "post",
      url: `${process.env.GATSBY_FORM_ENDPOINTS_URL}${process.env.GATSBY_FORM_ENDPOINTS_WEBSITE_NAME}/subscribe`,
      data: postData,
    })
      .then(response => {
        console.log("Endpoint response", response)
        if (Boolean(response.data.success)) {
          trackSubscribe()
          setFormSubmitted(true)
        }
        setFormSending(false)
      })
      .catch(error => {
        console.log("Endpoint error", error)
        let errorMessage =
          "There was a problem submitting your details. Please try again later."
        if (error.hasOwnProperty("response")) {
          if (error.response) {
            if (error.response.data.hasOwnProperty("message")) {
              errorMessage = error.response.data.message
              setValidationErrors([])
            } else if (error.response.data.hasOwnProperty("validation")) {
              //override dob message
              if (error.response.data.validation.dob) {
                error.response.data.validation.dob = getDateOfBirthErrors(
                  dateOfBirthString,
                  false
                )
              }
              setValidationErrors(error.response.data.validation)
              errorMessage = "Please check the form for errors."
            }
          } else if (error.request) {
            console.log("Request", error.request)
          } else {
            console.log("Setup", error.message)
          }
        }
        setFormError(errorMessage)
        setFormSending(false)
      })
  }

  // const recaptchaVerified = (response) => {
  //     console.log('Recaptcha response', response);
  //     setRecaptchaResponse(response);
  // }

  const getDateOfBirthErrors = (dateString, setDob) => {
    // let dobError = ""

    // First check for the pattern
    if (!/^\d{1,2}\/\d{1,2}\/\d{2,4}$/.test(dateString)) {
      return "Your date of birth must be in the format dd/MM/YYYY (e.g. 25/12/1990)."
    }

    // Parse the date parts to integers
    var parts = dateString.split("/")
    var day = parseInt(parts[0], 10)
    var month = parseInt(parts[1], 10)
    var year = parseInt(parts[2], 10)

    // Check the ranges of month and year
    if (year < 1900) {
      return "Please check your date of birth. You don't look that old."
    }
    if (month < 1 || month > 12) {
      return "Please check your date of birth. Aren't there only 12 months?"
    }

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    // Adjust for leap years
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0))
      monthLength[1] = 29

    if (day < 1 || day > monthLength[month - 1]) {
      return `Please check your date of birth. There are only ${
        monthLength[month - 1]
      } days that month.`
    }

    let minAgeDate = new Date()
    minAgeDate.setFullYear(minAgeDate.getFullYear() - 18)
    let dobDate = new Date(year, month - 1, day)
    if (dobDate > minAgeDate) {
      return "You must be over 18 to subscribe."
    }

    if (setDob) {
      if (day < 10) {
        day = "0" + day
      }
      if (month < 10) {
        month = "0" + month
      }
      setDateOfBirth(year + "-" + month + "-" + day)
    }
    return ""
  }

  const changeDateOfBirth = dateString => {
    setDateOfBirthString(dateString)
    let dobError = getDateOfBirthErrors(dateString, true)
    validationErrors.dob = dobError
    setValidationErrors(validationErrors)
  }

  const siteLogo = props?.themeOptions?.ThemeOptions?.siteLogo?.mediaItemUrl

  return (
    <div className={`${classes.subscribePage} ${classes.subscribePageTheme}`}>
      <Button className="back-link" onClick={() => window.history.back()}>
        <span></span> Back
      </Button>
      {siteLogo && (
        <div className="site-logo">
          <Link to="/">
            <img src={siteLogo} alt="" />
          </Link>
        </div>
      )}
      <div className="subscribe-page">
        <div className="form-wrapper">
          <h2>Subscribe</h2>
          {formSubmitted && (
            <p className={classes.thanks}>
              <CheckCircleIcon /> Thank you for subscribing.
            </p>
          )}

          {!formSubmitted && formError.length !== 0 && (
            <p className={classes.errors}>
              <WarningIcon /> {formError}
            </p>
          )}
          {!formSubmitted && (
            <form>
              <label htmlFor="f-name" className={classes.label}>
                First Name
              </label>
              <TextField
                id="f-name"
                variant="outlined"
                className={`${classes.inputs} ${
                  validationErrors.forename ? "has-error" : ""
                }`}
                placeholder="Frederic"
                onChange={e => setForename(String(e.target.value))}
                aria-required={true}
                aria-invalid={Boolean(validationErrors.forename)}
              />
              {validationErrors.forename && (
                <p className={classes.validationError}>
                  {validationErrors.forename}
                </p>
              )}
              <label htmlFor="s-name" className={classes.label}>
                Last Name
              </label>
              <TextField
                id="s-name"
                variant="outlined"
                className={`${classes.inputs} ${
                  validationErrors.surname ? "has-error" : ""
                }`}
                placeholder="Robinson"
                onChange={e => setSurname(String(e.target.value))}
                aria-required={true}
                aria-invalid={Boolean(validationErrors.surname)}
              />
              {validationErrors.surname && (
                <p className={classes.validationError}>
                  {validationErrors.surname}
                </p>
              )}
              <label htmlFor="email" className={classes.label}>
                Email Address
              </label>
              <TextField
                id="email"
                variant="outlined"
                className={`${classes.inputs} ${
                  validationErrors.email ? "has-error" : ""
                }`}
                placeholder="Frederic.Robinson@email.com"
                onChange={e => setEmail(String(e.target.value))}
                aria-required={true}
                aria-invalid={Boolean(validationErrors.email)}
              />
              {validationErrors.email && (
                <p className={classes.validationError}>
                  {validationErrors.email}
                </p>
              )}
              <label htmlFor="dob" className={classes.label}>
                Date of Birth
              </label>
              <TextField
                id="dob"
                variant="outlined"
                className={`${classes.inputs} ${
                  validationErrors.dob ? "has-error" : ""
                }`}
                placeholder="19/08/1988"
                onChange={e => changeDateOfBirth(String(e.target.value), true)}
                aria-required={true}
                aria-invalid={Boolean(validationErrors.dob)}
              />
              {validationErrors.dob && (
                <p className={classes.validationError}>
                  {validationErrors.dob}
                </p>
              )}
              {/*<ReactRecaptcha className={classes.recaptcha}*/}
              {/*sitekey={process.env.GATSBY_GRECAPTCHA_KEY}*/}
              {/*render="explicit"*/}
              {/*verifyCallback={recaptchaVerified}*/}
              {/*/>*/}
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={formSending}
                onClick={event => submitForm(event)}
              >
                Submit
              </Button>
            </form>
          )}
        </div>
        {props.themeOptions.ThemeOptions.subscribeFormImage != null && (
          <>
            <Img
              fluid={
                props.themeOptions.ThemeOptions.subscribeFormImage
                  .mediaItemUrlSharp.childImageSharp.fluid
              }
              alt=""
            />
          </>
        )}
        {props.themeOptions.ThemeOptions.subscribeFormImage === null &&
          props.themeOptions.ThemeOptions.navBackgroundImage != null && (
            <Img
              fluid={
                props.themeOptions.ThemeOptions.navBackgroundImage
                  .mediaItemUrlSharp.childImageSharp.fluid
              }
              alt=""
            />
          )}
      </div>
    </div>
  )
}
